import React from "react";
import { Container } from "react-bootstrap";

import CommingSoonImg from "../assets/images/blog.png";

const Blog = () => {
  return (
    <div className="blog-section" id="blog">
      <Container>
        <h1 className="main-title">Blog</h1>
        <div className="blog-wrapper">
          <img src={CommingSoonImg} alt="commingsoon" className="comming-img" />
          <div className="d-flex flex-column justify-content-center">
            <h5>Coming soon!</h5>
            <p>We are currently working on our blog.</p>
            <p>Thank you for your patience.</p>
          </div>
        </div>
      </Container>
    </div>
  );
};

export default Blog;
