import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import Step1 from "../assets/images/step1.png";
import Step2 from "../assets/images/step2.png";
import Step3 from "../assets/images/step3.png";
import Step4 from "../assets/images/step4.png";
import Step5 from "../assets/images/step5.png";
import Step6 from "../assets/images/step6.png";
import Step7 from "../assets/images/step7.png";
import Step8 from "../assets/images/step8.png";
import Step9 from "../assets/images/step9.png";

const Steps = () => {
  return (
    <div className="steps-section">
      <Container>
        <h1 className="main-title">Steps we follow</h1>
        <h5 className="sub-title">Follow instruction for more</h5>
        <Row>
          <Col md={4}>
            <div className="steps-single">
              <img src={Step1} alt="steps" className="steps-img" />
              <p>Step 1</p>
              <h5>Availability of land</h5>
            </div>
          </Col>
          <Col md={4}>
            <div className="steps-single">
              <img src={Step2} alt="steps" className="steps-img" />
              <p>Step 2</p>
              <h5>Availability of crops and miscellaneous</h5>
            </div>
          </Col>
          <Col md={4}>
            <div className="steps-single">
              <img src={Step3} alt="steps" className="steps-img" />
              <p>Step 3</p>
              <h5>Projection (quantity and rate)</h5>
            </div>
          </Col>
          <Col md={4}>
            <div className="steps-single">
              <img src={Step4} alt="steps" className="steps-img" />
              <p>Step 4</p>
              <h5>Order/Confirmation</h5>
            </div>
          </Col>
          <Col md={4}>
            <div className="steps-single">
              <img src={Step5} alt="steps" className="steps-img" />
              <p>Step 5</p>
              <h5>Cultivation</h5>
            </div>
          </Col>
          <Col md={4}>
            <div className="steps-single">
              <img src={Step6} alt="steps" className="steps-img" />
              <p>Step 6</p>
              <h5>Insurance</h5>
            </div>
          </Col>
          <Col md={4}>
            <div className="steps-single">
              <img src={Step7} alt="steps" className="steps-img" />
              <p>Step 7</p>
              <h5>Updates</h5>
            </div>
          </Col>
          <Col md={4}>
            <div className="steps-single">
              <img src={Step8} alt="steps" className="steps-img" />
              <p>Step 8</p>
              <h5>Delivery/Selling</h5>
            </div>
          </Col>
          <Col md={4}>
            <div className="steps-single">
              <img src={Step9} alt="steps" className="steps-img" />
              <p>Step 9</p>
              <h5>Wallet/Cash out process</h5>
            </div>
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default Steps;
