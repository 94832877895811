import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import Footer from "../components/Footer";
import Header from "../components/Header";
import AboutImg from "../assets/images/about-page.jpg";

const About = () => {
  return (
    <>
      <Header />
      <div className="page-content">
        <Container>
          <h1 className="main-title">About Us</h1>
          <h5 className="sub-title">
            A brief information about our farming culture.
          </h5>
          <Row>
            <Col md="6">
              <div className="about-page-wrapper">
                <h1 className="main-title">Virtual Farming? What's that?</h1>
                <p>
                  We are approaching the Block chained enabled cloud based
                  farming technology. Globally anyone can have virtual farming
                  land, where anyone can register in the system, and the user
                  can customize his land-based on seasonal and off-seasonal
                  crops. In the real scenario, farmers are planting the users'
                  crops. Users are facilitated with all the different stages of
                  farming and real photos, live video feeds, etc. Insurance
                  facilities are available to reduce the risk. Different courier
                  or delivery service company facilitates the delivery service
                  of the crops after being ready to serve. If users are far from
                  the country or transportation to their doorsteps is not
                  possible, they can monetize their products. wallet feature is
                  facilitated in the application so that they can utilize the
                  amount for further cultivation or can cash out the balance.
                  All the transactions are stored on the distributed ledger
                  system. Which maintains the track of the history of the
                  investors and farmers as well as maintaining transparency is
                  also easy.
                </p>
              </div>
            </Col>
            <Col md="6">
              <div className="about-img-wrapper">
                <img
                  src={AboutImg}
                  alt="about img"
                  className="about-page-img"
                />
              </div>
            </Col>
            <Col md="4">
              <div className="about-page-wrapper">
                <h1 className="main-title">What is Farming?</h1>
                <p>
                  Farming by meaning is “growing crops or keeping animals by
                  people for food and raw materials”. Farming is one of the
                  oldest profession estimated to be started more than 12000
                  years ago in Ancient Mesopotamia (modern-day Iraq, Jordan,
                  Syria, Israel, Palestine, southeastern Turkey and western
                  Iran). Prior to farming humans were hunters and gatherers.
                  Farming has played a great role in the development of
                  humankind, farming has allowed our ancestors to stay in one
                  location for many generations which helped them from the
                  extension. Due to farming, trade relation was established
                  between different regions and different groups of people which
                  fast-tracked the development. Farming has been a key factor
                  for economics throughout the centuries prior to and after the
                  Industrial revolution. Sustainable development of world food
                  supplies impact the long-term survival of the species, so care
                  must be taken to ensure that farming method remains in harmony
                  with the environment.
                </p>
              </div>
            </Col>
            <Col md="8">
              <div className="about-page-wrapper">
                <h1 className="main-title">
                  Problems with current farming model
                </h1>
                <p>
                  There have been many innovations in the field of farming,
                  however, the current farming model is not able to maintain a
                  sustainable supply chain moving in the future where we can
                  only use 12% of worlds land to feed 9 billion people. Current
                  farming also usages 70% of the world’s fresh water and at the
                  same time contribute a total of 12% of greenhouse gas
                  emissions. In addition to this farming already faces many
                  issues such as, - Economic, where farmers lack financially to
                  make a good investment to run the farm through the season to
                  deliver a quality product. - Environmental, where farmers are
                  impacted by soil quality, water quality, climate, and terrain.
                  - Political, where farmers are influenced by policies passed
                  such as what crops are planted, how products are grown,
                  transported and sold. - Supply and demand, where farmers lack
                  the right equipment to have sustainable growth. The price of
                  land is at a premium and many farmers are pressured to sell
                  their fields so the cities can expand. - Manpower, where
                  farmers don’t have enough labor to rely on to, new generation
                  not interested in farming and moving to cities looking for
                  better opportunities. Farming caused massive population growth
                  centuries ago and ever since humanity has never stopped
                  needing farmers. Despite the great need for farmers, most
                  modern farmers are only in the professions because it has been
                  a family business for several generations. Due to the less
                  interest from the current generation, farming has suffered
                  from a lack of skilled resources, game-changing innovations,
                  funding, etc. What if there was a way where we change the
                  current business model of farming? What if we can make a new
                  generation interested in farming and make breakthroughs as our
                  ancestors did many centuries ago? This is where we introduce
                  “Virtual Farming”.
                </p>
              </div>
            </Col>
            <Col md="12">
              <div className="about-page-wrapper">
                <h1 className="main-title">Virtual farming?</h1>
                <p>
                  Virtual farming addresses the issues faced by the current
                  farming society and intends to solve them by bringing the
                  investors from around the work and farmers together in a
                  virtual platform. This platform allows the investors to invest
                  in the upcoming farm (flexible to choose the crops), farmers
                  then usages the investment made by the investors to invest in
                  the right tooling and resources to make sure a good quality
                  product is produced. At the end of the harvest (averagely 6
                  months) farmers sells the products in the market and pays back
                  the investors a certain percentage on top of their principle
                  amount. This is a win-win solution for all parties. In order
                  to have a complete transparency and to avoid any fraud,
                  blockchain technology will be used to make a contract between
                  investors and farmers; this will then be stored in a
                  distributed ledger.
                </p>
              </div>
            </Col>
          </Row>
        </Container>
      </div>
      <Footer />
    </>
  );
};

export default About;
