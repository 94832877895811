import React from "react";
import { Col, Container, Row } from "react-bootstrap";

const Gallery = () => {
  return (
    <div className="gallery-section">
      <Container>
        <h1 className="main-title">Gallery</h1>
        <Row className="mt-4">
          <Col md={4}>
            <img
              src="https://images.pexels.com/photos/50707/cocoa-man-colombia-peasant-50707.jpeg?auto=compress&cs=tinysrgb&dpr=2&w=500"
              alt=""
              className="gallery-img-lg img-responsive"
            />
          </Col>
          <Col md={8}>
            <Row>
              <Col md={4}>
                <img
                  src="https://images.pexels.com/photos/1435904/pexels-photo-1435904.jpeg?auto=compress&cs=tinysrgb&dpr=2&h=750&w=1260"
                  alt=""
                  className="gallery-img-sm img-responsive"
                />
              </Col>
              <Col md={4}>
                <img
                  src="https://images.pexels.com/photos/296230/pexels-photo-296230.jpeg?auto=compress&cs=tinysrgb&dpr=2&h=750&w=1260"
                  alt=""
                  className="gallery-img-sm img-responsive"
                />
              </Col>
              <Col md={4}>
                <img
                  src="https://images.pexels.com/photos/1459339/pexels-photo-1459339.jpeg?auto=compress&cs=tinysrgb&dpr=2&w=500"
                  alt=""
                  className="gallery-img-sm img-responsive"
                />
              </Col>
              <Col md={4}>
                <img
                  src="https://images.pexels.com/photos/4117550/pexels-photo-4117550.jpeg?auto=compress&cs=tinysrgb&dpr=2&w=500"
                  alt=""
                  className="gallery-img-sm img-responsive"
                />
              </Col>
              <Col md={4}>
                <img
                  src="https://images.pexels.com/photos/1459451/pexels-photo-1459451.jpeg?auto=compress&cs=tinysrgb&dpr=2&w=500"
                  alt=""
                  className="gallery-img-sm img-responsive"
                />
              </Col>
              <Col md={4}>
                <img
                  src="https://images.pexels.com/photos/5561310/pexels-photo-5561310.jpeg?auto=compress&cs=tinysrgb&dpr=2&w=500"
                  alt=""
                  className="gallery-img-sm img-responsive"
                />
              </Col>
            </Row>
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default Gallery;
