import React from "react";
import { Container } from "react-bootstrap";
import IB from "../assets/images/part1.png";
import RM from "../assets/images/part2.png";

const Partners = () => {
  return (
    <div className="partners-section">
      <Container>
        <h1 className="main-title">Our Partners</h1>
        <div className="partners-wrapper">
          <div className="partner-single">
            <h5>Technology partner:</h5>
            <img src={IB} className="partner-img" alt="" />
          </div>
          <div className="partner-single">
            <h5>Global partner:</h5>
            <img src={RM} className="partner-img" alt="" />
          </div>
        </div>
      </Container>
    </div>
  );
};

export default Partners;
