import React from "react";
import { Col, Container, Row, Button } from "react-bootstrap";
import Header from "./Header";
import BannerImg from "../assets/images/banner.png";
import { Link } from "react-scroll";

const Banner = () => {
  return (
    <div className="banner" id="home">
      <Header />
      <div className="banner-section">
        <Container>
          <Row>
            <Col md={4}>
              <div className="banner-content">
                <h1>Grow</h1>
                <h2>virtually</h2>
                <p>
                  We are approaching the Block chained enabled cloud based
                  farming technology. Globally anyone can have virtual farming
                  land, where anyone can register in the system, and the user
                  can customize his land-based on seasonal and off-seasonal
                  crops.
                </p>
                <div className="btn-wrapper">
                  <Link
                    to="booking"
                    spy={true}
                    smooth={true}
                    className="btn btn-primary"
                  >
                    Book your plant
                  </Link>
                </div>
              </div>
            </Col>
            <Col md={8}>
              <img src={BannerImg} alt="banner" className="banner-img" />
            </Col>
          </Row>
        </Container>
      </div>
    </div>
  );
};

export default Banner;
