import { Tab } from "bootstrap";
import React, { useState } from "react";
import { Container, Row, Col, Button, Modal, Tabs } from "react-bootstrap";
import BookingImg from "../assets/images/book.png";
import Plant1 from "../assets/images/plant1.png";
import Plant2 from "../assets/images/plant2.png";
import Soon from "../assets/images/blog.png";
import { GrClose } from "react-icons/gr";

const Booking = () => {
  const [show, setShow] = useState(false);

  return (
    <div className="booking-section" id="booking">
      <Container>
        <div className="booking-wrapper">
          <Row>
            <Col md={6}>
              <div className="booking-content">
                <h1>Book your plant</h1>
                <p>
                  It’s not just booking the plants, It’s about saving the
                  Environment.
                </p>
                <div className="btn-wrapper">
                  <Button className="btn-primary" onClick={() => setShow(true)}>
                    Book your plant
                  </Button>
                </div>
              </div>
            </Col>
          </Row>
          <div className="booking-img">
            <img src={BookingImg} alt="booking" className="booking" />
          </div>
        </div>
        <Modal
          show={show}
          onHide={() => setShow(false)}
          className="modal-dialog-lg"
        >
          <Modal.Body>
            <div className="close-icon" onClick={() => setShow(false)}>
              <GrClose />
            </div>
            <h2 className="modal-title">Book your plant</h2>
            <Tabs
              defaultActiveKey="new-plant"
              id="uncontrolled-tab-example"
              className="mb-3"
            >
              <Tab eventKey="new-plant" title="New plant">
                <div className="d-flex justify-content-center align-items-center">
                  <div className="single-plant">
                    <img src={Plant1} className="plant-img" alt="" />
                    <h5 className="plant-name">Lemon</h5>
                    <p className="plant-rate">$99/8yrs/plant</p>
                    <div className="btn-wrapper">
                      <Button
                        className="btn-primary"
                        onClick={() => setShow(false)}
                      >
                        Book now
                      </Button>
                    </div>
                  </div>
                  <div className="single-plant">
                    <img src={Plant2} className="plant-img" alt="" />
                    <h5 className="plant-name">Macadamia Nut</h5>
                    <p className="plant-rate">$99/8yrs/plant</p>
                    <div className="btn-wrapper">
                      <Button
                        className="btn-primary"
                        onClick={() => setShow(false)}
                      >
                        Book now
                      </Button>
                    </div>
                  </div>
                </div>
              </Tab>
              <Tab eventKey="existing-plant" title="Existing plant">
                <div className="comming-soon">
                  <img src={Soon} className="comming-soon" alt="" />
                  <p>Comming Soon</p>
                </div>
              </Tab>
              <Tab eventKey="combo-set" title="Combo Set">
                <div className="comming-soon">
                  <img src={Soon} className="comming-soon" alt="" />
                  <p>Comming Soon</p>
                </div>
              </Tab>
              <Tab eventKey="choose-plant" title="Choose your plant">
                <div className="comming-soon">
                  <img src={Soon} className="comming-soon" alt="" />
                  <p>
                    Our expert will analyse the feasibility and and contact you
                    soon
                  </p>
                </div>
              </Tab>
            </Tabs>
          </Modal.Body>
        </Modal>
      </Container>
    </div>
  );
};

export default Booking;
