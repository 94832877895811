import React from "react";
import { Col, Container, Row } from "react-bootstrap";
import Logo from "../assets/images/lanka1.png";
import {
  FaLinkedin,
  FaFacebookSquare,
  FaInstagramSquare,
  FaPhone,
  FaEnvelope,
} from "react-icons/fa";

const Footer = () => {
  return (
    <div className="footer-section">
      <div className="footer-top">
        <Container>
          <Row>
            <Col md={2} xs={12} className="mb-3">
              <img src={Logo} className="footer-logo" alt="" />
            </Col>
            <Col md={3} xs={6} className="mb-3">
              <h5 className="footer-title">Location</h5>
              <p className="footer-subtitle mb-2">Palpa, Nepal</p>
              <div
                className="border-none"
                dangerouslySetInnerHTML={{
                  __html:
                    "<iframe src='https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3528.7168026543254!2d83.63990431484874!3d27.818486727206192!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x0%3A0x0!2zMjfCsDQ5JzA2LjUiTiA4M8KwMzgnMzEuNSJF!5e0!3m2!1sen!2snp!4v1624964121572!5m2!1sen!2snp' width='100%'/>",
                }}
              />
            </Col>
            <Col md={2} xs={12} className="mb-3">
              <h5 className="footer-title">Helpful</h5>
              <ul className="useful-links">
                <li>About us</li>
                <li>Help</li>
                <li>Service</li>
                <li>Privacy</li>
              </ul>
            </Col>
            <Col md={2} xs={12} className="mb-3">
              <h5 className="footer-title">Follow us</h5>
              <ul className="social-icons">
                <li>
                  <FaFacebookSquare />
                </li>
                <li>
                  <FaLinkedin />
                </li>
                <li>
                  <FaInstagramSquare />
                </li>
              </ul>
            </Col>
            <Col md={3} xs={12} className="mb-3">
              <h5 className="footer-title">Contact info</h5>
              <ul className="footer-contact">
                <li>
                  <div className="icon-wrapper">
                    <FaPhone />
                  </div>
                  <span>+977-9851123553</span>
                </li>
                <li>
                  <div className="icon-wrapper">
                    <FaEnvelope />
                  </div>
                  <span>info@lankafarm.com</span>
                </li>
              </ul>
            </Col>
          </Row>
        </Container>
      </div>
      <div className="footer-bottom">
        <p>Copyright @2021 Lanka Farm House - All rights reserved</p>
      </div>
    </div>
  );
};

export default Footer;
