import React from "react";
import { Col, Container, Row } from "react-bootstrap";
import { Link } from "react-router-dom";
import AboutImg from "../assets/images/about.png";

const About = () => {
  return (
    <div className="about-section" id="about">
      <Container>
        <Row>
          <Col md={4}>
            <div className="about-img-wrapper">
              <img src={AboutImg} alt="about-img" className="about-img" />
            </div>
          </Col>
          <Col md={8}>
            <div className="about-content-wrapper">
              <h1 className="main-title">About Us</h1>
              <p>
                We are approaching the Block chained enabled cloud based farming
                technology. Globally anyone can have virtual farming land, where
                anyone can register in the system, and the user can customize
                his land-based on seasonal and off-seasonal crops. In the real
                scenario, farmers are planting the users' crops. Users are
                facilitated with all the different stages of farming and real
                photos, live video feeds, etc. Insurance facilities are
                available to reduce the risk. Different courier or delivery
                service company facilitates the delivery service of the crops
                after being ready to serve. If users are far from the country or
                transportation to their doorsteps is not possible, they can
                monetize their products. wallet feature is facilitated in the
                application so that they can utilize the amount for further
                cultivation or can cash out the balance. All the transactions
                are stored on the distributed ledger system. Which maintains the
                track of the history of the investors and farmers as well as
                maintaining transparency is also easy.
              </p>
              <Link to="/about" className="btn btn-primary">
                Read more about us
              </Link>
            </div>
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default About;
