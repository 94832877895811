import React from "react";
import About from "../components/About";
import Banner from "../components/Banner";
import Blog from "../components/Blog";
import Booking from "../components/Booking";
import Gallery from "../components/Gallery";
import Services from "../components/Services";
import Steps from "../components/Steps";
import Partners from "../components/Partners";
import Contact from "../components/Contact";
import Footer from "../components/Footer";

const Home = () => {
  return (
    <>
      <Banner />
      <Services />
      <Steps />
      <About />
      <Booking />
      <Gallery />
      <Blog />
      <Partners />
      <Contact />
      <Footer />
    </>
  );
};

export default Home;
