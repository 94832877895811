import { useState } from "react";
import { Button, Form, Modal, Col } from "react-bootstrap";
import React from "react";
import logo from "../assets/images/lanka1.png";
import Signup from "../assets/images/signup.png";
import { GrClose } from "react-icons/gr";
import { FaBars } from "react-icons/fa";
import { Link as MainLink } from "react-router-dom";
import { Link } from "react-scroll";

const Header = () => {
  const [show, setShow] = useState(false);
  const [open, setOpen] = useState(false);
  return (
    <div className="header">
      <div className="container">
        <div className="header-wrapper">
          <MainLink to="/">
            <img src={logo} alt="logo" className="logo" />
          </MainLink>
          {/* <ul className="navigation">
            <li className="nav-item">Home</li>
            <li className="nav-item">Services</li>
            <li className="nav-item">About Us</li>
            <li className="nav-item">Book your plant</li>
            <li className="nav-item">Blog</li>
          </ul> */}
          <div
            class={open ? `button_container active` : `button_container`}
            id="toggle"
            onClick={() => setOpen(!open)}
          >
            <span class="top"></span>
            <span class="middle"></span>
            <span class="bottom"></span>
          </div>
          <div class={open ? `overlay open` : `overlay`} id="overlay">
            <nav class="overlay-menu">
              <ul>
                <li className="nav-item">
                  <MainLink to="/" onClick={() => setOpen(!open)}>
                    Home
                  </MainLink>
                </li>
                <li className="nav-item">
                  <Link
                    to="services"
                    spy={true}
                    smooth={true}
                    onClick={() => setOpen(!open)}
                  >
                    Services
                  </Link>
                </li>
                <li className="nav-item">
                  <Link
                    to="about"
                    spy={true}
                    smooth={true}
                    onClick={() => setOpen(!open)}
                  >
                    About Us
                  </Link>
                </li>
                <li className="nav-item">
                  <Link
                    to="booking"
                    spy={true}
                    smooth={true}
                    onClick={() => setOpen(!open)}
                  >
                    Book your plant
                  </Link>
                </li>
                <li className="nav-item">
                  <Link
                    to="blog"
                    spy={true}
                    smooth={true}
                    onClick={() => setOpen(!open)}
                  >
                    Blog
                  </Link>
                </li>
              </ul>
            </nav>
          </div>
          {/* <div className="btn-wrapper">
            <Button className="btn-primary" onClick={() => setShow(true)}>
              Login/Register
            </Button>
          </div> */}
          {/* <FaBars className="toggle-bar" size="32" color="#247001" /> */}
        </div>
      </div>
      <Modal show={show} onHide={() => setShow(false)}>
        <Modal.Body>
          <div className="close-icon" onClick={() => setShow(false)}>
            <GrClose />
          </div>
          <div className="d-flex flex-column align-items-center">
            <h2 className="modal-title">Sign up</h2>
            <p className="modal-subtitle">
              Sign Up to be notified when the app is available and be the first
              to download !
            </p>
            <div className="img-wrapper">
              <img src={Signup} className="signup" alt="" />
            </div>
            <Form className="my-5">
              <Form.Row>
                <Form.Group as={Col}>
                  <Form.Label>First name</Form.Label>
                  <Form.Control type="text" placeholder="Enter first name" />
                </Form.Group>
                <Form.Group as={Col}>
                  <Form.Label>Last name</Form.Label>
                  <Form.Control type="text" placeholder="Enter last name" />
                </Form.Group>
              </Form.Row>
              <Form.Row>
                <Form.Group as={Col}>
                  <Form.Label>Email</Form.Label>
                  <Form.Control type="email" placeholder="Enter your email" />
                </Form.Group>
              </Form.Row>
              <div className="btn-wrapper mt-3">
                <Button className="btn-primary" onClick={() => setShow(false)}>
                  Sign up
                </Button>
              </div>
            </Form>
          </div>
        </Modal.Body>
      </Modal>
    </div>
  );
};

export default Header;
