import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import Service1 from "../assets/images/serv1.png";
import Service2 from "../assets/images/serv2.png";
import Service3 from "../assets/images/serv3.png";

const Services = () => {
  return (
    <div className="services-section" id="services">
      <Container>
        <h1 className="main-title">Services</h1>
        <h5 className="sub-title">Follow instruction for more</h5>
        <Row>
          <Col md={4}>
            <div className="services-single">
              <img src={Service1} alt="services" className="services-img" />
              <h5>Home Stay</h5>
              <p>We are available on AirBnB</p>
            </div>
          </Col>
          <Col md={4}>
            <div className="services-single">
              <img src={Service2} alt="services" className="services-img" />
              <h5>Work from village</h5>
              <p>
                Facilitated with personal house, organic food, good internet
                connection
              </p>
            </div>
          </Col>
          <Col md={4}>
            <div className="services-single">
              <img src={Service3} alt="services" className="services-img" />
              <h5>Hunting</h5>
              <p>Hunt animals. Only domestic animals for now</p>
            </div>
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default Services;
